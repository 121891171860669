<template>
  <div>
    <Detail
      :routeInfo="routeDetailInfo"
      @onCloseRouteDetail="resetHighlightPolyline"
      @onDeleteRoute="handleDeleteRoute"
      @onUpdateUsingState="handleUpdateUsingState"
      @onEditRoute="handleEditRoute"
    ></Detail>
    <Add ref="addRouteRef" :routeDetailInfo="routeDetailInfo"></Add>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Toast } from 'vant';
import bus from '@/utils/bus';
import { normalMarkerIcon, selectedMarkerIcon, zIndexMax } from '@/utils/constantMap';
import { getZone, getRouteById } from '@/api/api';
import Detail from './Detail.vue';
import Add from './Add.vue';
let polylineOverlayGroup, // 线集合
  partMarkerOverlayGroup, // 用于部分POI,高亮线时显示关联的POI,把总的POI隐藏
  motorwaysLineOverlayGroup, // 机动车道线
  motorwayDataArr = [], //机动车道的数据
  timestampRoute, // 记录点击路线请求详情的时间戳
  highlightPolyline, // 高亮的线
  routeStrokeWeight = 6, // 路线的宽度
  size = 40, // 图标大小
  hSize = 56; // 图标高亮大小

const polylineConfig = {
  strokeWeight: 6, //线的宽度
  motorwayStrokeWeight: 6, //机动车道线的宽度
  polylineStrokeColor: '#FABEC4', //线颜色
  polylineStrokeHighColor: '#FF391B', //线高亮颜色
  polylineOutlineColor: '#F09292', // 线条描边颜色
  motorwaysStrokeColor: '#EDE574', // 机动车道线颜色
  motorwaysStrokeHighColor: '#FFC300', // 机动车道线高亮颜色
  motorwaysOutlineColor: '#F2D027', // 线条描边颜色
  isOutline: true, // 是否显示描边
};
export default {
  props: ['mapZoom', 'onlyShowPoi'],
  components: { Detail, Add },
  watch: {
    mapZoom: function () {},
    mapZoom: {
      handler() {
        if (this.mapZoom < 11) {
          routeStrokeWeight = 3;
        } else {
          routeStrokeWeight = 6;
        }
        this.onChangeRouteStrokeWeight();
      },
      immediate: true,
    },
  },
  data() {
    return {
      routeDetailInfo: null,
    };
  },
  computed: {
    ...mapGetters({
      parkInfo: 'getParkInfo',
      drawStatus: 'getDrawStatus',
      isShowLabel: 'getisShowLabelLogisticsMap',
      authBtn: 'getAuthBtn',
    }),
  },
  methods: {
    // 渲染路线图层,采用局部更新,移除不存在的
    renderPolylineOverlayGroup({ routeData, hightItem }) {
      let polylineArr = [];
      motorwayDataArr = [];
      for (const route of routeData) {
        if (this.parkInfo?.id) {
          const polyline = this.drawPolyline(route);
          polylineArr.push(polyline);
          if (this.routeDetailInfo && this.routeDetailInfo.id === route.id && !this.onlyShowPoi) {
            //当前线高亮
            polyline.setOptions({
              showDir: true,
              zIndex: zIndexMax,
              strokeColor: polylineStrokeColor,
            });
            highlightPolyline = polyline;
          }
          if (hightItem?.id === route.id) {
            polyline.emit('click', { target: polyline });
          }
        } else {
          const polylinOverlay = this.polylineSearch(route);
          if (!polylinOverlay || (polylinOverlay && polylinOverlay.index === -1)) {
            const polyline = this.drawPolyline(route);
            polylineArr.push(polyline);
            if (hightItem?.id === route.id) {
              polyline.emit('click', { target: polyline });
            }
          }
        }
      }
      if (polylineOverlayGroup) {
        if (this.parkInfo?.id) {
          polylineOverlayGroup.clearOverlays();
        }
        polylineOverlayGroup.addOverlays(polylineArr);
      } else {
        polylineOverlayGroup = new AMap.OverlayGroup(polylineArr);
        this.$parent.$options.map.add(polylineOverlayGroup);
      }
      if (motorwaysLineOverlayGroup) {
        if (this.parkInfo?.id) {
          motorwaysLineOverlayGroup.clearOverlays();
        }
        motorwaysLineOverlayGroup.addOverlays(motorwayDataArr);
      } else {
        motorwaysLineOverlayGroup = new AMap.OverlayGroup(motorwayDataArr);
        this.$parent.$options.map.add(motorwaysLineOverlayGroup);
      }
    },
    // 绘制线
    drawPolyline(line) {
      const { routeList } = line.mapData;
      const polyline = new AMap.Polyline({
        path: routeList,
        cursor: 'pointer',
        strokeStyle: 'solid',
        strokeOpacity: 1,
        strokeWeight: routeStrokeWeight,
        strokeColor: polylineConfig.polylineStrokeColor,
        extData: line,
        zIndex: line.id * 10,
        isOutline: polylineConfig.isOutline,
        outlineColor: polylineConfig.polylineOutlineColor,
      });
      polyline.on('click', ({ target: Polyline }) => {
        if (this.drawStatus > 0) return;
        if (highlightPolyline) {
          const hExtData = highlightPolyline.getExtData();
          const extdata = Polyline.getExtData();
          if (hExtData.id === extdata.id) return;
        }
        this.getPolylineDetail(Polyline);
      });
      const vehicleLaneLineArr = line.mapData?.vehicleLaneLine
        ? JSON.parse(line.mapData?.vehicleLaneLine)
        : [];
      if (vehicleLaneLineArr?.length > 0) {
        vehicleLaneLineArr.forEach((lineArr, index) => {
          motorwayDataArr.push(this.drawMotorwaysLine(lineArr, line));
        });
      }
      return polyline;
    },
    // 绘制机动车道
    drawMotorwaysLine(path, detail) {
      if (path.length === 0) {
        path = [[0, 0]];
      }

      const line = new AMap.Polyline({
        path: path,
        strokeStyle: 'solid',
        strokeOpacity: 1,
        strokeWeight: routeStrokeWeight + 1,
        strokeColor: polylineConfig.motorwaysStrokeColor,
        zIndex: (detail.id + 1) * 10,
        bubble: true,
        extData: detail,
        isOutline: polylineConfig.isOutline,
        outlineColor: polylineConfig.motorwaysOutlineColor,
      });
      line.on('click', ({ target: MotorwayPolyline, lnglat }) => {
        if (this.drawStatus > 0) return;
        if (highlightPolyline) {
          const hExtData = highlightPolyline.getExtData();
          const extdata = MotorwayPolyline.getExtData();
          if (hExtData.id === extdata.id) return;
        }
        const extData = MotorwayPolyline.getExtData();
        const Polyline = this.polylineSearch(extData);
        this.getPolylineDetail(Polyline.overlay);
      });
      if (highlightPolyline && !this.onlyShowPoi) {
        const extData = highlightPolyline.getExtData();
        if (extData.id === detail.id) {
          line.setOptions({ showDir: true, zIndex: zIndexMax + 1 });
        }
      }
      return line;
    },
    // 查询指定polyline
    polylineSearch(payload) {
      if (!polylineOverlayGroup) return;
      const polylineOverlays = polylineOverlayGroup.getOverlays();
      const index = polylineOverlays.findIndex((overlay) => {
        const extData = overlay.getExtData();
        if (extData.id === payload.id) {
          return overlay;
        }
      });
      return {
        overlay: polylineOverlays[index],
        index,
      };
    },
    //  根据id查询线的详情数据
    async getPolylineDetail(Polyline) {
      const extData = Polyline.getExtData();
      timestampRoute = Date.now();
      const res = await getRouteById({ routeId: extData.id, timestamp: timestampRoute });
      if (res.timestamp !== timestampRoute || this.drawStatus > 0) return;
      const detail = {
        ...res,
        vehicleLaneLine: res.vehicleLaneLine
          ? JSON.stringify(JSON.parse(res.vehicleLaneLine).filter((item) => item.length > 0))
          : res.vehicleLaneLine,
        id: res.routeId,
        mapType: 'ROUTE',
        // quoteRoute: Polyline, // 用于编辑的时候,方便显示和隐藏
      };
      this.$emit('onClickOverlay', { val: 2 });
      // 自动驾驶路线需要进行坐标转换
      await this.formatPilotSubWayRoutePaths(
        detail.pilotSubWayRoutePaths,
        detail.pilotSubWayCoordinates,
        res.parkName,
        res.highPrecision
      );
      this.formatTransitPointData(detail);
      this.drawHighlightPolyline(detail, Polyline);
    },
    async formatPilotSubWayRoutePaths(
      pilotSubWayRoutePaths,
      pilotSubWayCoordinates,
      parkName,
      highPrecision
    ) {
      const firstCoordinates = pilotSubWayCoordinates[0];
      let zone = parseInt(firstCoordinates[0] / 6 + 31);
      if (
        highPrecision === 1 &&
        pilotSubWayRoutePaths.some((s) => s?.routePath.some((r) => !r.transitPointInfo))
      ) {
        // 有些老数据没有transitPointInfo,所以zone值请求一下
        const res = await getZone(firstCoordinates);
        if (res.errcode === 0 && res.data && res.data.length > 0) {
          if (res.data.length === 1) {
            zone = res.data[0].utm[2];
          } else if (res.data.length === 2) {
            this.$toast('存在多个地图信息');
          } else if (res.data.length === 0) {
            this.$toast('未获取到zone值');
          }
        } else {
          this.$toast(res.errmsg || '未获取到zone值');
        }
      }
      pilotSubWayRoutePaths.forEach((station) => {
        if (station.routePath?.length > 0) {
          station.routePath.forEach((dot) => {
            if (highPrecision) {
              // 高精路线的途经点需要做转换
              if (dot.transitPointInfo) {
                // 兼容途经点从utm转02偏移的问题
                const newLnglat = dot.transitPointInfo?.split(',');
                dot.lng = newLnglat[0];
                dot.lat = newLnglat[1];
              } else {
                const newLnglat = transform.utmToLonlat([{ x: dot.x, y: dot.y }], zone);
                dot.lng = newLnglat[0][0];
                dot.lat = newLnglat[0][1];
              }
            } else {
              dot.lng = dot.x;
              dot.lat = dot.y;
              dot.coordinate = [dot.x, dot.y];
            }
            dot.transitPoi = `${dot.x},${dot.y}`;
          });
        }
        station.parkName = parkName;
      });
    },
    formatTransitPointData(detail) {
      if (!detail.pilotSubWayRoutePaths) return;
      let transitPointArr = [];
      detail.pilotSubWayRoutePaths = detail.pilotSubWayRoutePaths.map((item) => {
        if (item.originalCoordinates) {
          const originalCoordinates = item.originalCoordinates.split(',');
          item.lng = originalCoordinates[0];
          item.lat = originalCoordinates[1];
        }
        // 途经点
        if (item.routePath?.length > 0) {
          item.routePath.forEach((dot, idx) => {
            transitPointArr.push({
              poiType: -3,
              id: -idx,
              name: idx + 1 + '',
              mapData: { lng: dot.lng, lat: dot.lat },
              isShowLabel: false,
            });
          });
        }
        return {
          ...item,
          id: item.stationId,
          name: item.stationName,
          poiType: item.poiType,
          mapData: { lng: item.lng, lat: item.lat },
          parkName: item.parkName,
        };
      });
      detail.transitPointArr = transitPointArr;
    },
    // 设置高亮
    drawHighlightPolyline(detail, Polyline) {
      bus.emit('onResetHighlightOverlay');
      bus.emit('onIshowMarkerOverlay', false);
      this.routeDetailInfo = detail;
      highlightPolyline = Polyline;
      if (motorwaysLineOverlayGroup) {
        motorwaysLineOverlayGroup.eachOverlay((motorwaysLine) => {
          const mExdata = motorwaysLine.getExtData();
          if (mExdata.id === detail.id) {
            motorwaysLine.setOptions({
              showDir: true,
              zIndex: zIndexMax + 1,
              strokeColor: polylineConfig.motorwaysStrokeHighColor,
            });
          }
        });
      }
      Polyline.setOptions({
        showDir: true,
        zIndex: zIndexMax,
        strokeColor: polylineConfig.polylineStrokeHighColor,
      });
      this.drawPartMarkerOverlayGroup(detail.pilotSubWayRoutePaths, detail.transitPointArr);
    },
    // 绘制当前路线关联的网点和途经点
    drawPartMarkerOverlayGroup(poiList, transitPointArr = []) {
      let markerArr = [];
      poiList.forEach((item) => {
        let isHigh = false;
        if (this.routeDetailInfo.id === item.id) {
          // POI编辑的时候需要把当前点高亮
          isHigh = true;
        }
        const marker = this.drawMarker(item, isHigh);
        markerArr.push(marker);
      });
      transitPointArr.forEach((item) => {
        markerArr.push(this.drawMarker(item));
      });
      if (partMarkerOverlayGroup) {
        partMarkerOverlayGroup.show();
        partMarkerOverlayGroup.clearOverlays();
        partMarkerOverlayGroup.addOverlays(markerArr);
      } else {
        partMarkerOverlayGroup = new AMap.OverlayGroup(markerArr);
        this.$parent.$options.map.add(partMarkerOverlayGroup);
      }
    },
    drawMarker(markerData, isHigh) {
      const { lng, lat } = markerData.mapData;
      let iconSize = markerData.poiType === -1 ? 32 : size;
      let image = normalMarkerIcon.get(markerData.poiType);
      let y = -(iconSize - 3);
      if (isHigh) {
        image = selectedMarkerIcon.get(markerData.poiType);
        iconSize = hSize;
        y = -(iconSize - 4);
      }
      const marker = new AMap.Marker({
        position: [lng, lat],
        icon: new AMap.Icon({
          size: new AMap.Size(iconSize, iconSize),
          image: image,
          imageSize: [iconSize, iconSize],
        }),
        offset: new AMap.Pixel(-(iconSize / 2), y), //相对于基点的偏移位置
        extData: markerData, // 设置其它数据
        zIndex: markerData.id * 100,
        bubble: true,
      });
      if (this.isShowLabel) {
        let content = `<div class='marker-label'>${markerData.name}</div>`;
        if (markerData.poiType === -3) {
          content = `<div class='marker-label tujing-marker-label'>途经点${markerData.name}</div>`;
        }
        marker.setLabel({
          direction: 'top',
          offset: new AMap.Pixel(-1, 0),
          content: content,
        });
      }
      return marker;
    },
    resetHighlightPolyline() {
      if (!highlightPolyline) return;
      bus.emit('onIshowMarkerOverlay', true);
      partMarkerOverlayGroup && partMarkerOverlayGroup.clearOverlays();
      partMarkerOverlayGroup = null;
      const { id } = this.routeDetailInfo;
      if (motorwaysLineOverlayGroup) {
        motorwaysLineOverlayGroup.eachOverlay((motorwaysLine) => {
          const mExdata = motorwaysLine.getExtData();
          if (mExdata.id === id) {
            motorwaysLine.setOptions({
              showDir: true,
              zIndex: (id + 1) * 10,
              strokeColor: polylineConfig.motorwaysStrokeColor,
              showDir: false,
            });
          }
        });
      }
      highlightPolyline.setOptions({
        showDir: true,
        zIndex: id,
        strokeColor: polylineConfig.polylineStrokeColor,
        showDir: false,
      });
      highlightPolyline = null;
    },
    handleDeleteRoute() {
      if (this.routeDetailInfo?.id) {
        const routeOverlay = this.polylineSearch(this.routeDetailInfo);
        this.deleteRoute(this.routeDetailInfo, routeOverlay.overlay);
        this.routeDetailInfo = null;
        highlightPolyline = null;
        partMarkerOverlayGroup && partMarkerOverlayGroup.clearOverlays();
        partMarkerOverlayGroup = null;
        bus.emit('onIshowMarkerOverlay', true);
        this.$store.commit('updateIsShowdetail', 0);
      }
    },
    deleteRoute(info, routeOverlay) {
      polylineOverlayGroup && polylineOverlayGroup.removeOverlay(routeOverlay);
      if (motorwaysLineOverlayGroup) {
        motorwaysLineOverlayGroup.eachOverlay((motorwaysLine) => {
          const mExdata = motorwaysLine.getExtData();
          if (mExdata.id === info.routeId) {
            motorwaysLine.remove();
          }
        });
      }
    },
    // 修改当前覆盖物的属性
    onChangeOverlayOptions(payload) {
      this.overlayStyle = payload.common;
      polylineOverlayGroup && polylineOverlayGroup.setOptions(this.overlayStyle);
      motorwaysLineOverlayGroup && motorwaysLineOverlayGroup.setOptions(this.overlayStyle);
    },
    onChangeRouteStrokeWeight() {
      polylineOverlayGroup && polylineOverlayGroup.setOptions({ strokeWeight: routeStrokeWeight });
      motorwaysLineOverlayGroup &&
        motorwaysLineOverlayGroup.setOptions({ strokeWeight: routeStrokeWeight + 1 });
    },
    handleUpdateUsingState() {
      this.routeDetailInfo.usingState = this.routeDetailInfo.usingState === 1 ? 0 : 1;
    },
    handleLabelChange() {
      if (!partMarkerOverlayGroup) return;
      partMarkerOverlayGroup.eachOverlay((overlay) => {
        const extData = overlay.getExtData();
        if (this.isShowLabel) {
          let content = `<div class='marker-label'>${extData.name}</div>`;
          if (extData.isHigh) {
            content = `<div class='marker-label marker-label-hight'><span>${
              extData.stationIndex + 1
            }</span>${extData.name}</div>`;
          }
          if (extData.poiType === -3) {
            content = `<div class='marker-label tujing-marker-label'>途经点${extData.name}</div>`;
          }
          overlay.setLabel({
            direction: 'top',
            offset: new AMap.Pixel(0, 0),
            content: content,
          });
        } else {
          overlay.setLabel({
            content: ' ',
          });
        }
      });
    },
    handleSelectLocation(payload) {
      if (payload.locationType !== 2 || !payload.mapData) return;
      this.$parent.$options.map.setCenter(payload.mapData.centerPoint, true);
      const routeOverlay = this.polylineSearch(payload);
      this.$emit('onClickOverlay', { val: 2 });
      if (~routeOverlay.index) {
        routeOverlay.overlay.emit('click', { target: routeOverlay.overlay });
      } else {
        this.renderPolylineOverlayGroup({ routeData: [payload], hightItem: payload });
      }
    },
    // 是否展示路线,绘制路线的时候需要隐藏
    handleIshowPolylineOverlayGroup(val) {
      if (!polylineOverlayGroup) return;
      if (val) {
        polylineOverlayGroup.show();
        motorwaysLineOverlayGroup && motorwaysLineOverlayGroup.show();
      } else {
        polylineOverlayGroup.hide();
        motorwaysLineOverlayGroup && motorwaysLineOverlayGroup.hide();
      }
    },
    // 编辑路线
    handleEditRoute() {
      const allOverlays = partMarkerOverlayGroup.getOverlays();
      if (allOverlays.length > 2 && this.routeDetailInfo.highPrecision === 0) {
        this.$toast('线路中存在多途经点，请至NEM平台操作');
        return;
      }
      this.resetHighlightPolyline();
      this.$store.commit('updateIsShowdetail', 0);
      this.$store.commit('updateDrawStatus', 2);
      bus.emit('onIshowMarkerOverlay', false);
      this.handleIshowPolylineOverlayGroup(false);
      this.$refs.addRouteRef.editDrawRoute(this.routeDetailInfo);
    },
    handleAddNewRoute(payload) {
      // 判断是否是编辑.如果是编辑需要删除之前的信息,然后新增
      if (payload.isEditing) {
        const polylineRes = this.polylineSearch(payload);
        this.deleteRoute(payload, polylineRes.overlay);
      }
      const lineData = {
        ...payload,
        mapData: {
          mapId: payload.mapId,
          routeList: payload.pilotSubWayCoordinates,
          lineRgb: payload.color,
          centerPoint: [payload.lng, payload.lat],
          vehicleLaneLine: payload.vehicleLaneLine,
        },
        mapType: 'ROUTE',
      };
      let polyline = this.drawPolyline(lineData);
      polylineOverlayGroup.addOverlay(polyline);
      // 绘制机动车道
      const vehicleLaneLineArr = payload?.vehicleLaneLine
        ? JSON.parse(payload?.vehicleLaneLine)
        : [];
      if (vehicleLaneLineArr?.length > 0) {
        let motorwayDataArrTemp = [];
        vehicleLaneLineArr.forEach((lineArr, index) => {
          motorwayDataArrTemp.push(this.drawMotorwaysLine(lineArr, lineData));
        });
        motorwaysLineOverlayGroup.addOverlays(motorwayDataArrTemp);
      }
      setTimeout(() => {
        polyline.emit('click', { target: polyline });
      });
    },
    // 开启路线绘制
    handleDrawOverlay(val) {
      polylineOverlayGroup && polylineOverlayGroup.setOptions({ bubble: true });
      if (val !== 2) return;
      this.handleIshowPolylineOverlayGroup(false);
      this.$refs.addRouteRef.addDrawRoute();
    },
    // 处理bus的监听事件
    monitorBus() {
      // 名字显示切换
      bus.on('onNameClick', this.handleLabelChange);
      // 点击地图高亮消失
      bus.on('onResetHighlightOverlay', this.resetHighlightPolyline);
      // 修改地图覆盖物的属性,比如采集的时候,要bubble要设置为true,并且手势修改
      bus.on('onChangeOverlayOptions', this.onChangeOverlayOptions);
      // 关键字搜索指定路线
      bus.on('onSelectLocation', this.handleSelectLocation);
      // 控制路线的显示和隐藏,绘制路线的时候要隐藏
      bus.on('onIshowPolylineOverlayGroup', this.handleIshowPolylineOverlayGroup);
      // 开启路线绘制
      bus.on('onDrawOverlay', this.handleDrawOverlay);
      bus.on('onAddNewRoute', this.handleAddNewRoute);
    },
  },
  mounted() {
    this.monitorBus();
  },
};
</script>
<style scoped lang="scss"></style>
