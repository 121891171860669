<template>
  <div class="step-one">
    <div class="step-tilte">① 选择站点</div>
    <div class="step-des">在地图中选中点位可设置为站点</div>
    <div class="station-list">
      <div class="station">
        <div class="station-label">起点</div>
        <div
          class="station-name"
          :class="{ placeholder: !startStationName }"
          @click="stationClick(0)"
        >
          {{ startStationName ? startStationName : '请选择站点' }}
        </div>
        <img class="arrow" src="@/assets/LogisticsMap/arrow-right.png" />
      </div>
      <div class="station">
        <div class="station-label">终点</div>
        <div
          class="station-name"
          :class="{ placeholder: !endStationName }"
          @click="stationClick(1)"
        >
          {{ endStationName ? endStationName : '请选择站点' }}
        </div>
        <img class="arrow" src="@/assets/LogisticsMap/arrow-right.png" />
      </div>
    </div>
    <div
      class="build-route"
      :class="{ 'primary-btn-disabled': isDisabledBuild }"
      @click="buildRouteClick"
    >
      {{ isStationPointChange ? '生成行驶线路' : '下一步' }}
    </div>
    <template v-if="isShowPopup">
      <van-popup
        class="station-box"
        v-model="isShowPopup"
        position="bottom"
        @close="onCancel"
        style="border-radius: 16px 16px 0px 0px"
      >
        <van-picker
          :title="stationType === 0 ? '请选择起点' : '请选择终点'"
          :columns="stationList"
          :columns-field-names="{ text: 'name', value: 'id' }"
          :default-index="defaultIndex"
          :show-toolbar="true"
          value-key="name"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
    </template>
  </div>
</template>
<script>
import { Toast } from 'vant';

export default {
  components: {},
  props: ['routeDetailInfo', 'stationList', 'isStationPointChange'],
  data() {
    return {
      isShowPopup: false,
      stationType: -1, // 0 起点 1 终点
    };
  },

  computed: {
    startStationName() {
      const station = this.routeDetailInfo?.pilotSubWayRoutePaths[0];
      return station.stationName;
    },
    endStationName() {
      const station = this.routeDetailInfo?.pilotSubWayRoutePaths[1];
      return station.stationName;
    },
    defaultIndex() {
      if (!~this.stationType) return 0;
      const station = this.routeDetailInfo?.pilotSubWayRoutePaths[this.stationType];
      const index = this.stationList.findIndex((item) => station.id === item.id);
      return ~index ? index : 0;
    },
    isDisabledBuild() {
      return !this.startStationName || !this.endStationName;
    },
  },
  methods: {
    stationClick(val) {
      this.isShowPopup = true;
      this.stationType = val;
    },
    buildRouteClick() {
      if (this.isDisabledBuild) return;
      if (this.startStationName === this.endStationName) {
        this.$toast('相邻的两个站点不允许重复');
        return;
      }
      this.$emit('onChangeStep', { step: 2 });
    },
    onConfirm(value) {
      this.$emit(
        'onChangeStaion',
        Object.assign({ index: this.stationType, station: { ...value, stationName: value.name } })
      );
      this.isShowPopup = false;
      this.stationType = -1;
    },
    onCancel() {
      this.isShowPopup = false;
      this.stationType = -1;
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.step-one {
  width: 100%;
  padding: 16px 14px 32px;
  box-sizing: border-box;
  background: white;
  border-radius: 16px 16px 0px 0px;
  .step-tilte {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: center;
  }
  .step-des {
    width: 100%;
    height: 34px;
    border-radius: 4px;
    background: rgba(254, 242, 234, 1);
    margin: 16px 0 10px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(254, 128, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .station-list {
    .station {
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 5px;
      padding-right: 17px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(234, 238, 238, 1);
      .station-label {
        width: 81px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(151, 152, 171, 1);
        text-align: left;
      }
      .station-name {
        flex: 1;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; /* 这里是超出几行省略 */
        overflow: hidden;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        &.placeholder {
          color: rgba(191, 192, 203, 1);
          font-weight: unset;
        }
      }
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
  .build-route {
    width: 100%;
    height: 46px;
    opacity: 1;
    border-radius: 23px;
    background: rgba(70, 95, 253, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: white;
    margin-top: 13px;
    &.primary-btn-disabled {
      opacity: 0.2;
    }
  }
}
</style>
