<template>
  <div v-if="drawStatus === 3">
    <Add v-if="!editing" :parkInfo="parkInfo"></Add>
    <Edit v-else :parkOverlayInfo="parkDetailInfo"></Edit>
  </div>
  <Detail
    v-else
    :parkDetailInfo="parkDetailInfo"
    @onCloseParkDetail="resetHighlightOverlay"
    @onEditPark="editPark"
  ></Detail>
</template>
<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
import { defaultPolygonConfig } from '@/utils/constantMap';
import Detail from './Detail.vue';
import Add from './Add.vue';
import Edit from './Edit.vue';

let polygonOverlayGroup, // 面集合
  highlightPolygon, // 高亮的围栏
  targetPolygon; // 选中的围栏
export default {
  name: 'Park',
  props: ['mapZoom'],
  components: { Detail, Add, Edit },
  data() {
    return {
      editing: false, // 新增or编辑
      overlayStyle: null, // 记录当前修改覆盖物样式,防止覆盖物没有加载出来的时候,就点击采集
      parkDetailInfo: null, // 点击围栏获取详情信息
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      parkInfo: 'getParkInfo',
      drawStatus: 'getDrawStatus',
    }),
  },
  methods: {
    renderPolygonOverlayGroup({ parkData }) {
      let polygonArr = [];
      for (const park of parkData) {
        if (this.parkInfo?.id) {
          polygonArr.push(this.drawPolygon(park));
        } else {
          const polygonOverlay = this.polygonSearch(park);
          if (!polygonOverlay || (polygonOverlay && polygonOverlay.index === -1)) {
            polygonArr.push(this.drawPolygon(park));
          }
        }
      }
      if (polygonOverlayGroup) {
        if (this.parkInfo?.id) {
          polygonOverlayGroup.clearOverlays();
        }
        polygonOverlayGroup.addOverlays(polygonArr);
      } else {
        polygonOverlayGroup = new window.AMap.OverlayGroup(polygonArr);
        this.$parent.$options.map.add(polygonOverlayGroup);
      }
      if (this.overlayStyle) {
        polygonOverlayGroup.setOptions(this.overlayStyle);
        this.overlayStyle = null;
      }
    },
    drawPolygon(aoi) {
      const { lineRgb, fenceRgb, fenceTransparency, regionFenceList, sortIndex } = aoi.mapData;
      const polygon = new window.AMap.Polygon({
        path: regionFenceList,
        cursor: 'pointer',
        ...defaultPolygonConfig,
        strokeColor: lineRgb || '#E89B00',
        fillColor: fenceRgb || '#FFEB3B',
        fillOpacity: fenceTransparency || 0.3,
        extData: aoi,
        zIndex: sortIndex,
      });
      polygon.on('click', ({ target: Polygon }) => {
        if (this.drawStatus > 0) return;
        this.$emit('onClickOverlay', { val: 3 });
        bus.emit('onResetHighlightOverlay');
        targetPolygon = Polygon;
        this.getPolypoyDetail(Polygon);
      });
      return polygon;
    },
    /**
     * 查询指定polygon
     * @param {*} item
     */
    polygonSearch(item) {
      if (!polygonOverlayGroup) return;
      const polygonOverlays = polygonOverlayGroup.getOverlays();
      const index = polygonOverlays.findIndex((overlay) => {
        const extData = overlay.getExtData();
        if (extData.id === item.id) {
          return overlay;
        }
      });
      return {
        overlay: polygonOverlays[index],
        index,
      };
    },
    // 围栏详情
    getPolypoyDetail(Polygon) {
      let detail = Polygon.getExtData();
      detail.fenceRgb = detail.fenceRgb ? detail.fenceRgb : '#FFEB3B';
      detail.fenceTransparency = detail.fenceTransparency ? detail.fenceTransparency : 0.3;
      detail.lineRgb = detail.lineRgb ? detail.lineRgb : '#E89B00';
      this.parkDetailInfo = {
        ...detail,
      };
      highlightPolygon = Polygon;
      Polygon.setOptions({ strokeWeight: 4, strokeStyle: 'solid', fillOpacity: 0.5 });
      // this.drawHighlightPolygon();
    },
    drawHighlightPolygon() {
      const { regionFenceList, fenceRgb, sortIndex } = this.parkDetailInfo.mapData;
      const option = {
        strokeOpacity: 0.6,
        strokeWeight: 8,
        strokeColor: '#10EBE0',
        fillColor: fenceRgb,
        fillOpacity: 0.3,
        zIndex: sortIndex + 10,
      };
      highlightPolygon = new window.AMap.Polygon({
        path: regionFenceList,
        ...option,
        cursor: 'pointer',
        extData: this.parkDetailInfo,
      });
      this.$parent.$options.map.add(highlightPolygon);
    },
    // 重置高亮
    resetHighlightOverlay() {
      if (highlightPolygon) {
        highlightPolygon.setOptions({ strokeWeight: 2, strokeStyle: 'dashed', fillOpacity: 0.3 });
        highlightPolygon = null;
      }
      // highlightPolygon && highlightPolygon.remove();
    },
    // 修改当前覆盖物的属性
    onChangeOverlayOptions(payload) {
      this.overlayStyle = payload.common;
      polygonOverlayGroup && polygonOverlayGroup.setOptions(this.overlayStyle);
    },
    // 删除围栏
    handleDeletePolygon(polygonData) {
      const polygon = this.polygonSearch(polygonData);
      if (~polygon.index && polygonOverlayGroup) {
        polygonOverlayGroup && polygonOverlayGroup.remove(polygon.overlay);
      }
    },
    // 新增/编辑
    handleAddNewPark(polygonData) {
      // 判断是否是编辑.如果是编辑需要删除之前的信息,然后新增
      if (polygonData.isEditing) {
        targetPolygon.destroy?.();
        this.editing = false;
      }
      const polygon = this.drawPolygon(polygonData);
      polygonOverlayGroup.addOverlay(polygon);
      setTimeout(() => {
        polygon.emit('click', { target: polygon });
      });
    },
    // 监听隐藏点,比如高亮选中线的时候,需要把点隐藏
    handleHideParkOverlay(isShow) {
      if (!polygonOverlayGroup) return;
      if (isShow) {
        polygonOverlayGroup.show();
      } else {
        polygonOverlayGroup.hide();
      }
    },
    /**
     * drawStatus 绘制的类型,点线面
     */
    handleDrawOverlay(drawStatus) {
      polygonOverlayGroup && polygonOverlayGroup.setOptions({ bubble: true });
      if (drawStatus !== 3) return;
      // console.log('开始采集面');
    },
    // 处理bus的监听事件
    monitorBus() {
      bus.on('onResetHighlightOverlay', this.resetHighlightOverlay); // 点击地图高亮消失
      // 修改地图覆盖物的属性,比如采集的时候,要bubble要设置为true,并且手势修改
      bus.on('onChangeOverlayOptions', this.onChangeOverlayOptions);
      bus.on('onDrawOverlay', this.handleDrawOverlay);
      bus.on('onHideOverlay', this.handleHideParkOverlay);
      bus.on('onAddNewPark', this.handleAddNewPark);
      bus.on('cancelEditPark', this.cancelEditPark);
    },
    editPark() {
      this.resetHighlightOverlay();
      this.$store.commit('updateDrawStatus', 3);
      this.editing = true;
      targetPolygon.hide();
    },
    // 取消编辑---重新显示
    cancelEditPark() {
      targetPolygon.show();
      this.editing = false;
      targetPolygon.emit('click', { target: targetPolygon });
    },
  },
  mounted() {
    this.monitorBus();
  },
  destroyed() {
    bus.all.clear();
  },
};
</script>
