<template>
  <div class="step-three" v-show="routeDetailInfo.highPrecision === 0">
    <div class="step-tilte">
      <div v-show="isAdding" class="cancel-add" @click="canceCurrentMotorway">取消</div>
      <span>③ 设置机动车道</span>
      <div
        v-show="motorwayLinesList.length > 0 && !isAdding"
        class="add-motorway-btn"
        @click="addVehicleLaneLineClick"
      >
        <img src="@/assets/LogisticsMap/add-blue.png" /><span>继续添加</span>
      </div>
    </div>
    <div
      v-show="motorwayLinesList.length === 0 && !isAdding"
      class="default-add-btn"
      @click="addVehicleLaneLineClick"
    >
      <img src="../../../../../assets/LogisticsMap/plus-gray.png" />
      <span>添加一个机动车道</span>
    </div>
    <div v-show="!isAdding" class="motorway-lines-list">
      <div
        v-for="(item, idx) in vehicleLaneLine"
        :key="'motorwayLine_' + idx"
        class="motorway-line"
      >
        <div>
          <span class="motorway-line-index">机动车道{{ idx + 1 }}</span>
          <span class="motorway-line-distance">{{
            computedmotorDistance(computedDistance(idx))
          }}</span>
        </div>
        <div class="motorway-line-clear" @click="deleteMotorwayClick(idx)">
          <img src="@/assets/imgs/input_clear.png" />
        </div>
      </div>
    </div>
    <div v-if="isAdding">
      <div class="step-des">单击两个菱形点，将两点间的线路设为机动车道</div>
      <div class="add-bottom-box">
        <div class="reset" :class="{ disabled: isDisabledReset }" @click="resetClick">
          <img src="@/assets/imgs/reset.png" alt="" />
          <span>重置</span>
        </div>
        <div
          class="complete-add"
          :class="{ 'primary-btn-disabled': isDisabledComplete }"
          @click="onCompleteAddClick"
        >
          完成
        </div>
      </div>
    </div>
    <div v-else class="bottom-box">
      <div class="pre" @click="preClick">上一步</div>
      <div class="next" @click="nextClick">
        {{ motorwayLinesList.length === 0 ? '跳过' : '下一步' }}
      </div>
    </div>
  </div>
</template>
<script>
import selectedMotorway from '@/assets/LogisticsMap/marker/normal-motorway-marker.png';
import normalMotorway from '@/assets/LogisticsMap/marker/selected-motorway-marker.png';
import { zIndexMax } from '@/utils/constantMap';
import NDialog from '@/components/NDialog'; // 支持promise的dialog
import { debounce } from '@/utils/index';

let map,
  motorwaysMarkerOverlayGroup, //机动车的黄色点
  firstMarker, // 机动绘制起点
  secondMarker, // 机动绘制终点
  repeatMotorwayArr, // 车道下标,记录当前绘制的车道覆盖之前那些机动车道
  infoWindowMotorway, // 信息弹窗体实例
  debouncedAction;

const polylineConfig = {
  strokeWeight: 6, //线的宽度
  motorwaysStrokeHighColor: '#FFC300', // 机动车道线高亮颜色
};
export default {
  components: {},
  props: ['routeDetailInfo', 'routePath', 'step'],
  data() {
    return {
      isAdding: false,
      isDisabledReset: true,
      isDisabledComplete: true,
      motorwayLines: [], // 当前绘制的机动车道
      vehicleLaneLine: [], // 机动车道的坐标列表
      motorwayLinesList: [], // 机动车道线列表
    };
  },
  computed: {},
  methods: {
    preClick() {
      this.hideOrShowMotorwayLines(false);
      this.$emit('onChangeStep', { step: 2 });
      infoWindowMotorway && infoWindowMotorway.close();
    },
    nextClick() {
      motorwaysMarkerOverlayGroup && motorwaysMarkerOverlayGroup.hide();
      infoWindowMotorway && infoWindowMotorway.close();
      this.$emit('onChangeStep', { step: 4, vehicleLaneLine: this.vehicleLaneLine });
    },
    // 添加机动车道
    addVehicleLaneLineClick() {
      infoWindowMotorway && infoWindowMotorway.close();
      this.isAdding = true;
      // 把已经绘制的机动车道的中间点隐藏,防止在上面绘制短的机动车道,造成重复
      motorwaysMarkerOverlayGroup.eachOverlay((marker) => {
        const mPosition = marker.getPosition();
        const coordinateStr = `${mPosition.getLng()},${mPosition.getLat()}`;
        for (let i = 0; i < this.motorwayLinesList.length; i++) {
          const lineArr = this.motorwayLinesList[i];
          for (let j = 0; j < lineArr.length; j++) {
            let path = this.formatLineCoordinate(lineArr[j]);
            if (path.length < 3) {
              break;
            } else {
              let midpointArr = path.slice(2, path.length - 1);
              let isHide = false;
              if (midpointArr.flat(2).join(',').includes(coordinateStr)) {
                // 当前点是机动车道绘制的中间点,就隐藏
                marker.hide();
                isHide = true;
              }
              const extData = marker.getExtData();
              marker.setExtData({ ...extData, isHide });
            }
          }
        }
      });
    },
    // 取消添加机动车道
    canceCurrentMotorway() {
      this.isAdding = false;
      this.motorwayLines.forEach((line) => {
        line.remove();
      });
      this.motorwayLines = [];
      this.resetMotorwayMarker();
    },
    // 重置
    resetClick() {
      this.motorwayLines.forEach((line) => {
        line.remove();
      });
      this.motorwayLines = [];
      this.resetMotorwayMarker(true);
      this.isDisabledComplete = true;
    },
    // 删除机动车道
    deleteMotorwayClick(idx) {
      this.motorwayLinesList[idx].forEach((line) => {
        line.remove();
      });
      this.motorwayLinesList.splice(idx, 1);
      this.vehicleLaneLine.splice(idx, 1);
      if (infoWindowMotorway) {
        const windowExtData = infoWindowMotorway.getExtData();
        if (windowExtData.index === idx) {
          infoWindowMotorway.close();
          infoWindowMotorway = null;
        }
      }
    },
    // 完成机动车道
    async onCompleteAddClick() {
      if (this.isDisabledComplete) return;
      let pathArr = [];
      this.motorwayLines.forEach((line) => {
        let paths = this.formatLineCoordinate(line);
        pathArr.push(paths);
      });
      // 校验当前绘制的机动车道是否覆盖之前绘制的
      repeatMotorwayArr = [];
      for (let i = 0; i < this.motorwayLinesList.length; i++) {
        let motorwayArr = this.motorwayLinesList[i];
        let isCover = false;
        for (let j = 0; j < motorwayArr.length; j++) {
          const curPath = this.formatLineCoordinate(motorwayArr[j]);
          isCover = pathArr.flat(2).join(',').includes(curPath.flat(2).join(','));
          if (isCover) {
            repeatMotorwayArr.push(i + 1);
            break;
          }
        }
      }
      if (repeatMotorwayArr.length > 0) {
        // 覆盖之前的机动车道了,给予提示
        let modalDes = '';
        repeatMotorwayArr.forEach((item) => {
          modalDes += `机动车道${item}`;
        });
        const result = await NDialog.confirm({
          title:
            '检测到当前设置的机动车道覆盖了本线路中其他的机动车道，若确定则会将其删除，具体如下：',
          message: modalDes,
        });
        if (result === 'confirm') {
          for (let i = repeatMotorwayArr.length - 1; i >= 0; i--) {
            this.deleteMotorwayClick(repeatMotorwayArr[i] - 1);
          }
          this.completetMotorway();
        }
      } else {
        this.completetMotorway();
      }
    },
    // 点击完成按钮
    completetMotorway() {
      this.resetMotorwayMarker();
      this.isAdding = false;
      this.isDisabledComplete = true;
      this.motorwayLinesList.push(this.motorwayLines);
      let pathArr = [];
      this.motorwayLines.forEach((line) => {
        let paths = this.formatLineCoordinate(line);
        pathArr.push(paths);
      });
      this.vehicleLaneLine.push(pathArr);
      this.motorwayLines = [];
    },
    // 绘制marker点,进行机动车道的选择
    drawMotorwaysMarkerOverlayGroup() {
      map = this.$parent.$parent.$parent.$parent.$options.map;
      const linePath = this.routePath;
      let markerArr = [];
      linePath.forEach((coordinate) => {
        const marker = this.motorwaysMarker(coordinate);
        markerArr.push(marker);
      });
      if (motorwaysMarkerOverlayGroup) {
        motorwaysMarkerOverlayGroup.clearOverlays();
        motorwaysMarkerOverlayGroup.addOverlays(markerArr);
      } else {
        motorwaysMarkerOverlayGroup = new AMap.OverlayGroup(markerArr);
        map.add(motorwaysMarkerOverlayGroup);
      }
      if (this.vehicleLaneLine.length === 0 && this.routeDetailInfo?.vehicleLaneLine?.length > 0) {
        this.vehicleLaneLine = this.routeDetailInfo?.vehicleLaneLine;
        this.vehicleLaneLine.forEach((oneArr) => {
          let motorwayArr = [];
          oneArr.forEach((motorway) => {
            motorwayArr.push(this.motorwaysLine(motorway));
          });
          this.motorwayLinesList.push(motorwayArr);
        });
      }
    },
    motorwaysMarker(coordinate) {
      const marker = new AMap.Marker({
        position: coordinate,
        icon: this.motorwayMarkerIcon(normalMotorway),
        offset: new AMap.Pixel(-8, -8), //相对于基点的偏移位置
        extData: { coordinate: coordinate.join(',') },
        zIndex: zIndexMax,
        bubble: false,
      });
      marker.on('click', ({ target: Maker }) => {
        if (!this.isAdding) return;
        // 手机上点击一次,会触发两个,所以使用防抖
        debouncedAction(Maker);
      });
      return marker;
    },
    // 点击机动车道点
    clickMotorwaysMarker(Maker) {
      if (firstMarker && this.isSameMarker(Maker, firstMarker)) {
        //  第一个选中的点再次被点击,设置为取消
        firstMarker.setIcon(this.motorwayMarkerIcon(normalMotorway));
        firstMarker = null;
      } else if (secondMarker && this.isSameMarker(Maker, secondMarker)) {
        //  第二个选中的点再次被点击,设置为取消
        secondMarker.setIcon(this.motorwayMarkerIcon(normalMotorway));
        secondMarker = null;
      } else if (!firstMarker) {
        // 设置第一个点
        firstMarker = Maker;
        firstMarker.setIcon(this.motorwayMarkerIcon(selectedMotorway));
      } else if (firstMarker && !secondMarker) {
        // 设置第二个点
        secondMarker = Maker;
        secondMarker.setIcon(this.motorwayMarkerIcon(selectedMotorway));
      } else if (firstMarker && secondMarker) {
        // 2.点击其它点,第二个点替换
        secondMarker.setIcon(this.motorwayMarkerIcon(normalMotorway));
        secondMarker = Maker;
        secondMarker.setIcon(this.motorwayMarkerIcon(selectedMotorway));
      }
      this.isDisabledReset = !(firstMarker || secondMarker);
      this.isDisabledComplete = !(firstMarker && secondMarker);
      // 生成机动车道线
      this.addMotorwaysLine();
    },
    motorwayMarkerIcon(image) {
      const icon = new AMap.Icon({
        size: new AMap.Size(16, 16),
        image: image,
        imageSize: new AMap.Size(16, 16),
      });
      return icon;
    },
    isSameMarker(oMarker, sMarker) {
      const oPosition = oMarker.getPosition();
      const sPosition = sMarker.getPosition();
      return oPosition.equals(sPosition);
    },
    addMotorwaysLine() {
      this.motorwayLines.forEach((line) => {
        line.remove();
      });
      if (!firstMarker || !secondMarker) return;
      this.motorwayLines = [];
      const fExtData = firstMarker.getExtData();
      const sExtData = secondMarker.getExtData();
      const fPositionStr = fExtData.coordinate;
      const sPositionStr = sExtData.coordinate;
      let pathArr = this.routePath;
      let coordinateArr = pathArr.map((coordinate) => coordinate.join(','));
      let startIdx = coordinateArr.findIndex((item) => item === fPositionStr);
      let endIdx = coordinateArr.findIndex((item) => item === sPositionStr);
      if (startIdx > endIdx && startIdx > -1 && endIdx > -1) {
        let temp = endIdx;
        endIdx = startIdx;
        startIdx = temp;
      }
      let motorwayPath = pathArr.slice(startIdx, endIdx + 1);
      this.motorwayLines.push(this.motorwaysLine(motorwayPath));
    },
    motorwaysLine(path) {
      const line = new AMap.Polyline({
        path: path,
        cursor: 'pointer',
        showDir: true,
        strokeColor: polylineConfig.motorwaysStrokeHighColor,
        strokeWeight: polylineConfig.strokeWeight + 1,
        strokeOpacity: 1,
        bubble: false,
        extData: { path },
        zIndex: zIndexMax - 100,
      });
      line.on('click', ({ target: polyline, lnglat }) => {
        if (this.step !== 3 || this.isAdding) return;
        // 当前机动车道绘制完成,再次点击弹窗展示当前车道
        this.createInfoWindow(polyline, lnglat);
      });
      map.add(line);
      return line;
    },
    createInfoWindow(motorwaysLine, position) {
      const motorwaysLineData = motorwaysLine.getExtData();
      const index = this.motorwayIndexInArray(motorwaysLineData.path);
      const infoContent = `<div class='info-window-motorway'>
      <span class="motorway-name">机动车道 ${index + 1}</span>
      <span class='delete_motorway'  id='delete_motorway_${index}'></span>
      </div>`;
      const extData = { ...motorwaysLineData, index };
      if (infoWindowMotorway) {
        infoWindowMotorway.setExtData(extData);
        infoWindowMotorway.setContent(infoContent);
      } else {
        infoWindowMotorway = new AMap.InfoWindow({
          content: infoContent,
          offset: new AMap.Pixel(-6, -2),
          extData: extData,
          isCustom: true,
          closeWhenClickMap: true, // 是否在鼠标点击地图后关闭信息窗体，默认false
          autoMove: false,
        });
        infoWindowMotorway.on('close', (e) => {
          // resetHighlightMarker();
        });
      }
      // 打开信息窗体
      infoWindowMotorway.open(map, position);
      // 获取按钮元素
      var button = document.getElementById(`delete_motorway_${index}`);
      // 添加点击事件监听器，并将当前Marker的数据信息传递到处理函数中
      let _this = this;
      button.addEventListener('click', function (e) {
        const windowExtData = infoWindowMotorway.getExtData();
        _this.deleteMotorwayClick(windowExtData.index);
      });
    },
    // 判断当前机动车道线在已添加数组中的下标
    motorwayIndexInArray(path) {
      for (let i = 0; i < this.motorwayLinesList.length; i++) {
        const lines = this.motorwayLinesList[i];
        const index = lines.findIndex((line) => {
          const curPath = this.formatLineCoordinate(line);
          return curPath.flat(1).join(',') === path.flat(1).join(',');
        });
        if (~index) return i;
      }
      return -1;
    },
    // 机动车道的显示和隐藏
    hideOrShowMotorwayLines(isShow) {
      if (motorwaysMarkerOverlayGroup) {
        isShow ? motorwaysMarkerOverlayGroup.show() : motorwaysMarkerOverlayGroup.hide();
      }
      this.motorwayLinesList.forEach((lines) => {
        lines.forEach((line) => {
          isShow ? line.show() : line.hide();
        });
      });
    },
    // 判断被调整的线段是否存在机动车道，如果不存在，则不清空机动车道；若被调整的线段存在机动车道，则清空对应的机动车道，
    updateMotorwayLines() {
      let coordinateArr = this.routePath;
      coordinateArr = coordinateArr.map((coordinate) => [
        this.fiveNum(coordinate[0]),
        this.fiveNum(coordinate[1]),
      ]);
      const lCoordinateStr = coordinateArr.flat(2).join(',');
      let newVehicleLaneLine = [];
      let newMotorwayLines = this.motorwayLinesList.filter((lines, index) => {
        let isChange = false;
        for (let i = 0; i < lines.length; i++) {
          let mCoordinateArr = [];
          const path = lines[i].getPath();
          path.forEach((coordinate) => {
            mCoordinateArr.push([
              this.fiveNum(coordinate.getLng()),
              this.fiveNum(coordinate.getLat()),
            ]);
          });
          isChange = !lCoordinateStr.includes(mCoordinateArr.flat(2).join(','));
          if (isChange) {
            // 比如机动车道1这个数组里面有一条线发生变化了,不用再循环了,整个机动车道1都清空
            break;
          }
        }
        if (isChange) {
          lines.forEach((line) => line.remove());
        } else {
          // 机动车道没有改变,记录下来
          newVehicleLaneLine.push(this.vehicleLaneLine[index]);
        }
        return !isChange;
      });
      this.motorwayLinesList = newMotorwayLines;
      this.vehicleLaneLine = newVehicleLaneLine;
    },
    fiveNum(num) {
      return Math.floor(num * 100000) / 100000;
    },
    // 清除机动车道样式
    clearMotorwayLines() {
      this.motorwayLinesList.forEach((lines) => {
        lines.forEach((line) => {
          line.remove();
        });
      });
      this.motorwayLines.forEach((line) => {
        line.remove();
      });
      this.motorwayLinesList = [];
      this.motorwayLines = [];
      this.vehicleLaneLine = [];
      this.isAdding = false;
      this.vehicleLaneLineArr = [];
      motorwaysMarkerOverlayGroup && motorwaysMarkerOverlayGroup.clearOverlays();
      infoWindowMotorway && infoWindowMotorway.close();
      this.isDisabledComplete = true;
      this.isDisabledReset = true;
    },
    // 充值路径点的样式
    resetMotorwayMarker(isRest) {
      if (secondMarker) {
        secondMarker.setIcon(this.motorwayMarkerIcon(normalMotorway));
        secondMarker = null;
      }
      if (firstMarker) {
        firstMarker.setIcon(this.motorwayMarkerIcon(normalMotorway));
        firstMarker = null;
      }
      this.isDisabledReset = true;
      motorwaysMarkerOverlayGroup.eachOverlay((marker) => {
        const extData = marker.getExtData();
        if (extData.isHide && isRest) {
          marker.hide();
        } else {
          marker.setExtData({ ...extData, isHide: false });
          marker.show();
        }
      });
    },
    // 获取当前线段的坐标
    formatLineCoordinate(polyline) {
      const path = polyline.getPath();
      const arr = [];
      path.forEach((item) => {
        arr.push([item.getLng(), item.getLat()]);
      });
      return arr;
    },
    // 计算机动车道的距离
    computedDistance(index) {
      const temp = this.vehicleLaneLine[index];
      let len = 0;
      temp.forEach((element) => {
        len += AMap.GeometryUtil.distanceOfLine(element);
      });
      return Math.round(len);
    },
    // 格式化长度
    computedmotorDistance(value) {
      if (value > 1000) {
        return `${(value / 1000).toFixed(2)} km`;
      }
      return `${value} m`;
    },
  },
  mounted() {
    debouncedAction = debounce(this.clickMotorwaysMarker, 100, true);
  },
};
</script>
<style scoped lang="scss">
.step-three {
  width: 100%;
  padding: 16px 14px 32px;
  box-sizing: border-box;
  background: white;
  border-radius: 16px 16px 0px 0px;
  .step-tilte {
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: center;
    .add-motorway-btn {
      position: absolute;
      top: 0;
      right: 0;
      img {
        width: 14px;
        margin-right: 8px;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        color: rgba(70, 95, 253, 1);
        vertical-align: text-top;
      }
    }
    .cancel-add {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
    }
  }
  .default-add-btn {
    margin-top: 21px;
    padding: 0 20px;
    width: 100%;
    height: 46px;
    background: url(../../../../../assets/LogisticsMap/dashed-border.png) no-repeat center;
    background-size: 100% 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 14px;
      margin-right: 4px;
    }
    span {
      font-size: 14px;
      font-weight: 600;
      color: rgba(191, 192, 203, 1);
    }
  }
  .step-des {
    width: 100%;
    height: 34px;
    border-radius: 4px;
    background: rgba(254, 242, 234, 1);
    margin: 16px 0;
    font-size: 14px;
    font-weight: 500;
    color: rgba(254, 128, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .motorway-lines-list {
    margin-top: 17px;
    max-height: 162px;
    overflow-y: scroll;
    .motorway-line {
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 5px;
      font-size: 16px;
      border-bottom: 1px solid #eaeeee;
      .motorway-line-index {
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
      }
      .motorway-line-distance {
        font-weight: 400;
        color: rgba(151, 152, 171, 1);
        margin-left: 14px;
      }
      .motorway-line-clear {
        width: 36px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 18px;
        }
      }
    }
  }
  .info-item {
    width: 100%;
    height: 54px;
    padding: 5px 0 5px 5px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(234, 238, 238, 1);
    display: flex;
    align-items: center;
    .k {
      width: 66px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(151, 152, 171, 1);
    }
    .v {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      .route-name {
        flex: 1;
        height: 100%;
      }
    }
  }
  .bottom-box {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .pre {
      width: 106px;
      height: 46px;
      border-radius: 23px;
      border: 2px solid rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      color: rgba(70, 95, 253, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .next {
      width: 220px;
      height: 46px;
      border-radius: 23px;
      background: rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      &.primary-btn-disabled {
        opacity: 0.2;
      }
    }
  }
  .add-bottom-box {
    display: flex;
    justify-content: space-between;
    .reset {
      display: flex;
      align-items: center;
      &.disabled {
        opacity: 0.3;
      }
      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 39.2px;
        color: rgba(0, 0, 0, 1);
      }
    }
    .complete-add {
      width: 86px;
      height: 34px;
      border-radius: 79px;
      background: #465ffd;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
      &.primary-btn-disabled {
        opacity: 0.2;
      }
    }
  }
}
</style>
