<template>
  <div class="first-guide">
    <div class="guide-header">新手教程</div>
    <div class="guide-step">
      <span class="step-name" :class="{ active: step === 1 }">设置网点</span>
      <img class="step-arrow" src="@/assets/LogisticsMap/stepArrow.png" alt="" />
      <span class="step-name" :class="{ active: step === 2 }">采集点位</span>
      <img class="step-arrow" src="@/assets/LogisticsMap/stepArrow.png" alt="" />
      <span class="step-name" :class="{ active: step === 3 }">规划路线</span>
    </div>
    <div class="guide-content">
      <video
        v-if="step === 1"
        muted
        loop
        autoplay
        playsinline
        poster="/assets/images/poster/poster1.jpg"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/H5_guide/1.mp4"
      ></video>
      <video
        v-if="step === 2"
        muted
        loop
        autoplay
        playsinline
        poster="/assets/images/poster/poster2.jpg"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/H5_guide/2.mp4"
      ></video>
      <video
        v-if="step === 3"
        muted
        loop
        autoplay
        playsinline
        poster="/assets/images/poster/poster2.jpg"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/H5_guide/3.mp4"
      ></video>
    </div>
    <div class="guide-footer">
      <div v-if="step !== 1" class="guide-btn prev-btn" @click="step--">上一步</div>
      <div class="guide-btn next-btn" @click="nextClick">
        <span v-if="step === 3">完成</span>
        <span v-else>下一步</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FirstGuide',
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    nextClick() {
      this.step++;
      if (this.step === 4) {
        this.step = 1;
        this.$emit('finish');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.first-guide {
  height: 100%;
  border-radius: 16px 16px 0px 0px;
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 70%, rgba(235, 239, 255, 1) 100%);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  .guide-header {
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0px;
    color: rgba(70, 95, 253, 1);
  }
  .guide-step {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    .step-name {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      color: rgba(151, 152, 171, 1);
      &.active {
        color: rgba(70, 95, 253, 1);
      }
    }
    .step-arrow {
      width: 20px;
      height: 20px;
    }
  }
  .guide-content {
    flex: 1;
    padding: 28px 0;
    box-sizing: border-box;
    overflow: hidden;
    video {
      display: inline-block;
      pointer-events: none;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border: 1px solid rgba(191, 192, 203, 1);
    }
  }
  .guide-footer {
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: 12px;

    .guide-btn {
      flex: 1;
      height: 40px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      border-radius: 24px;
      cursor: pointer;
      &:active {
        transform: scale(0.95);
      }
    }
    .prev-btn {
      color: rgba(70, 95, 253, 1);
      border: 2px solid rgba(70, 95, 253, 1);
    }
    .next-btn {
      flex: 1;
      background: rgba(67, 110, 255, 1);
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
</style>
