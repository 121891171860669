var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShowDetail === 3)?_c('div',[_c('Bottom',{scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"detail"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"top-left"},[_c('div',{staticClass:"icon",style:({
                border: `2px dashed ${_vm.setLineRgb(_vm.parkDetailInfo)}`,
                background: _vm.hexToRgba(
                  _vm.parkDetailInfo?.mapData?.fenceRgb,
                  _vm.parkDetailInfo?.mapData?.fenceTransparency,
                  _vm.parkDetailInfo
                ),
              })}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.parkDetailInfo?.name)+" ")])]),_c('div',{staticClass:"top-right",on:{"click":_vm.closeClick}},[_c('img',{attrs:{"src":require("../../../../assets/LogisticsMap/common/close-popup.png")}})])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-item special"},[_c('div',{staticClass:"k"},[_vm._v(" 城市："),_c('span',{staticClass:"v"},[_vm._v(_vm._s(_vm.parkDetailInfo?.proAndCity))])]),_c('div',{staticClass:"aoi-type"},[_vm._v(" "+_vm._s(_vm.parkDetailInfo?.aoiTypeName)+" ")])]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"k"},[_vm._v(" 点位数量："),_c('span',{staticClass:"v"},[_vm._v(_vm._s(_vm.parkDetailInfo?.poiNum || '-'))])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.parkDetailInfo?.cpLabelName),expression:"parkDetailInfo?.cpLabelName"}],staticClass:"content-item m_t_4"},[_c('div',{staticClass:"k"},[_vm._v(" 所属CP："),_c('span',{staticClass:"v"},[_vm._v(_vm._s(_vm.parkDetailInfo?.cpLabelName || '-'))])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHasBtnPermission && _vm.parkDetailInfo?.aoiType === 1),expression:"isHasBtnPermission && parkDetailInfo?.aoiType === 1"}],staticClass:"detail-bottom"},[_c('div',{directives:[{name:"permission",rawName:"v-permission",value:(['编辑标注']),expression:"['编辑标注']"}],staticClass:"edit-btn",on:{"click":_vm.editClick}},[_vm._v("编辑")])])])]},proxy:true}],null,false,3771348986)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }