// 定义一些常量枚举值

import shifaNormal from '@/assets/LogisticsMap/marker/shifa/shifa-normal.png';
import shifaSelected from '@/assets/LogisticsMap/marker/shifa/shifa-selected.png';
import shifaSelectedRed from '@/assets/LogisticsMap/marker/shifa/shifa-selected-red.png';
import shifaCircle from '@/assets/LogisticsMap/marker/shifa/shifa-circle.png';
import jieboNormal from '@/assets/LogisticsMap/marker/jiebo/jiebo-normal.png';
import jieboSelected from '@/assets/LogisticsMap/marker/jiebo/jiebo-selected.png';
import jieboSelectedRed from '@/assets/LogisticsMap/marker/jiebo/jiebo-selected-red.png';
import jieboCircle from '@/assets/LogisticsMap/marker/jiebo/jiebo-circle.png';
import jieguanNormal from '@/assets/LogisticsMap/marker/jieguan/jieguan-normal.png';
import jieguanSelected from '@/assets/LogisticsMap/marker/jieguan/jieguan-selected.png';
import jieguanSelectedRed from '@/assets/LogisticsMap/marker/jieguan/jieguan-selected-red.png';
import jieguanCircle from '@/assets/LogisticsMap/marker/jieguan/jieguan-circle.png';
import facheNormal from '@/assets/LogisticsMap/marker/fache/fache-normal.png';
import facheSelected from '@/assets/LogisticsMap/marker/fache/fache-selected.png';
import facheSelectedRed from '@/assets/LogisticsMap/marker/fache/fache-selected-red.png';
import facheCircle from '@/assets/LogisticsMap/marker/fache/fache-circle.png';
import zhuangcheNormal from '@/assets/LogisticsMap/marker/zhuangche/zhuangche-normal.png';
import zhuangcheSelectedRed from '@/assets/LogisticsMap/marker/zhuangche/zhuangche-selected-red.png';
import zhuangcheSelected from '@/assets/LogisticsMap/marker/zhuangche/zhuangche-selected.png';
import zhuangcheCircle from '@/assets/LogisticsMap/marker/zhuangche/zhuangche-circle.png';
import xiecheNormal from '@/assets/LogisticsMap/marker/xieche/xieche-normal.png';
import xiecheSelected from '@/assets/LogisticsMap/marker/xieche/xieche-selected.png';
import xiecheSelectedRed from '@/assets/LogisticsMap/marker/xieche/xieche-selected-red.png';
import xiecheCircle from '@/assets/LogisticsMap/marker/xieche/xieche-circle.png';
import defaultNormal from '@/assets/LogisticsMap/marker/default-normal.png';
import defaultSelected from '@/assets/LogisticsMap/marker/default-selected.png';
import fengxianNormal from '@/assets/LogisticsMap/marker/fengxian/fengxian-normal.png';
import fengxianSelected from '@/assets/LogisticsMap/marker/fengxian/fengxian-selected.png';
import fengxianSelectedRed from '@/assets/LogisticsMap/marker/fengxian/fengxian-selected-red.png';
import fengxianSelectedGreen from '@/assets/LogisticsMap/marker/fengxian/fengxian-selected-green.png';
import fengxianCircle from '@/assets/LogisticsMap/marker/fengxian/fengxian-circle.png';
import tingcheNormal from '@/assets/LogisticsMap/marker/tingche/tingche-normal.png';
import tingcheSelected from '@/assets/LogisticsMap/marker/tingche/tingche-selected.png';
import tingcheCircle from '@/assets/LogisticsMap/marker/tingche/tingche-circle.png';
import tingcheSelectedRed from '@/assets/LogisticsMap/marker/tingche/tingche-selected-red.png';
import tingcheSelectedGeen from '@/assets/LogisticsMap/marker/tingche/tingche-selected-green.png';

import tujingdian from '@/assets/LogisticsMap/marker/tujing.png';
export const markerType = {
  default: {
    normal:
      'https://cybertron-public.oss-cn-beijing.aliyuncs.com/Frontend/unmannedVehicleLogisticsMap/LogisticsMap/marker/default-normal.png',
    selected:
      'https://cybertron-public.oss-cn-beijing.aliyuncs.com/Frontend/unmannedVehicleLogisticsMap/LogisticsMap/marker/default-selected.png',
  },
  1: {
    name: '网点',
    normal: shifaNormal,
    selected: shifaSelected,
    selectedRed: shifaSelectedRed,
    circle: shifaCircle,
  },
  2: {
    name: '接驳点',
    normal: jieboNormal,
    selected: jieboSelected,
    selectedRed: jieboSelectedRed,
    circle: jieboCircle,
  },
  3: {
    name: '风险点',
    normal: fengxianNormal,
    selected: fengxianSelected,
    selectedRed: fengxianSelectedRed,
    selectedGreen: fengxianSelectedGreen,
    circle: fengxianCircle,
  },
  4: {
    name: '接管点',
    normal: jieguanNormal,
    selected: jieguanSelected,
    selectedRed: jieguanSelectedRed,
    circle: jieguanCircle,
  },
  5: {
    name: '装车点',
    normal: zhuangcheNormal,
    selected: zhuangcheSelected,
    selectedRed: zhuangcheSelectedRed,
    circle: zhuangcheCircle,
  },
  6: {
    name: '停车点',
    normal: tingcheNormal,
    selected: tingcheSelected,
    selectedRed: tingcheSelectedRed,
    selectedGeen: tingcheSelectedGeen,
    circle: tingcheCircle,
  },
  7: {
    name: '发车点',
    normal: facheNormal,
    selected: facheSelected,
    selectedRed: facheSelectedRed,
    circle: facheCircle,
  },
  8: {
    name: '卸车点',
    normal: xiecheNormal,
    selected: xiecheSelected,
    selectedRed: xiecheSelectedRed,
    circle: xiecheCircle,
  },
  9: {
    name: '风险点',
    normal: fengxianNormal,
    selected: fengxianSelected,
    circle: fengxianCircle,
  },
};
// 默认状态下的图标
export const normalMarkerIcon = new Map([
  [-3, tujingdian],
  [-1, defaultNormal],
  [1, shifaNormal],
  [2, jieboNormal],
  [3, fengxianNormal],
  [4, jieguanNormal],
  [5, zhuangcheNormal],
  [6, tingcheNormal],
  [7, facheNormal],
  [8, xiecheNormal],
  [9, fengxianNormal],
]);
// 选中状态下的图标
export const selectedMarkerIcon = new Map([
  [-3, tujingdian],
  [-1, defaultSelected],
  [1, shifaSelected],
  [2, jieboSelected],
  [3, fengxianSelectedGreen],
  [4, jieguanSelected],
  [5, zhuangcheSelected],
  [6, tingcheSelected],
  [7, facheSelected],
  [8, xiecheSelected],
  [9, fengxianSelected],
]);
// 绘制围栏的默认属性
export const defaultPolygonConfig = {
  strokeColor: '#69E079',
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: '#69E079',
  fillOpacity: 0.3,
  strokeStyle: 'dashed',
};
// 围栏编辑的默认属性
export const defaultPolygonEditConfig = {
  editOptions: {
    // 绘制线的属性
    strokeColor: '#69E079',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: '#69E079',
    fillOpacity: 0.3,
    strokeStyle: 'dashed',
  },
  controlPoint: {
    // 顶点属性
    strokeColor: '#0C4FF2',
    radius: 7,
  },
  midControlPoint: {
    strokeColor: '#0C4FF2',
    fillColor: '#0C4FF2',
    radius: 7,
  },
};

// 1 网点 2 中转场 3 接驳 4 触点
export const fenceAOIColor = {
  1: { strokeColor: '#44B891', fillOpacity: 0.3, fillColor: '#69E079' },
  2: { strokeColor: '#E86B23', fillOpacity: 0.3, fillColor: '#FF9159' },
  3: { strokeColor: '#367DF7', fillOpacity: 0.3, fillColor: '#6BAEFF' },
  4: { strokeColor: '#C94EF2', fillOpacity: 0.3, fillColor: '#E085FF' },
};
// 最大index的值
export const zIndexMax = 2147483647;
