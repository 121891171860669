<template>
  <div class="backBar">
    <div class="backBar-btn" @click="$emit('click')">
      <img src="@/assets/imgs/back-icon-map.png" alt="" />
    </div>
    <span class="backBar-title">{{ title }}</span>
  </div>
</template>
<script>
export default {
  props: ['title'],
  data() {
    return {
      appBarHeight: parseInt(sessionStorage.getItem('_appBarHeight') ?? 0) + 10 + 'px',
    };
  },
};
</script>
<style lang="scss" scoped>
.backBar {
  pointer-events: none;
  width: 100%;
  height: 40px;
  position: fixed;
  top: var(--appBarHeight);
  display: flex;
  align-items: center;
  justify-content: center;
  &-btn {
    pointer-events: all;
    position: absolute;
    left: 10px;
    background: #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 32px;
    }
  }
  &-title {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }
}
</style>
