<template>
  <div class="step-three">
    <div class="step-tilte">
      <span class="center-title"> <span v-show="!isHighRoute">④ </span>填写信息</span>
      <span class="route-distance">{{ distance }}</span>
    </div>
    <div class="info-item">
      <div class="k">名称</div>
      <div class="v">
        <clear-input
          :value.sync="subwayName"
          maxlength="30"
          type="text"
          placeholder="请输入线路名称"
        ></clear-input>
      </div>
    </div>
    <div class="info-item">
      <div class="k">类型</div>
      <div class="v navigation-mode">
        <van-radio-group v-model="routeType" direction="horizontal">
          <van-radio name="1" checked-color="#465FFD">去程</van-radio>
          <van-radio name="2" checked-color="#465FFD">返程</van-radio>
        </van-radio-group>
      </div>
    </div>
    <div class="bottom-box">
      <div v-show="!isHighRoute" class="pre" @click="preClick">上一步</div>
      <div
        class="next"
        @click="completeClick"
        :class="{ 'primary-btn-disabled': subwayName.length === 0, 'w-100': isHighRoute }"
      >
        完成规划
      </div>
    </div>
  </div>
</template>
<script>
import ClearInput from '../../common/ClearInput.vue';

export default {
  components: { ClearInput },
  props: ['routeDetailInfo', 'routeDistance'],
  data() {
    return {
      subwayName: this.routeDetailInfo.subwayName || '',
      routeType: this.routeDetailInfo.routeType ? String(this.routeDetailInfo.routeType) : '1',
    };
  },
  computed: {
    distance() {
      if (this.routeDistance > 1000) {
        return `${(this.routeDistance / 1000).toFixed(2)} km`;
      }
      return `${this.routeDistance} m`;
    },
    isHighRoute() {
      return this.routeDetailInfo.highPrecision === 1;
    },
  },
  methods: {
    clearClick() {
      this.subwayName = '';
    },
    preClick() {
      this.$emit('onChangeStep', { step: 3 });
    },
    completeClick() {
      if (this.subwayName.length === 0) {
        return;
      }
      const reg = /^[\u4e00-\u9fa5a-zA-Z0-9-_·-（）()]*$/;
      if (!reg.test(this.subwayName)) {
        this.$toast('仅支持中英文及部分特殊字符');
        return;
      }
      this.$emit('onCompleteClick', { subwayName: this.subwayName, routeType: this.routeType });
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.step-three {
  width: 100%;
  padding: 16px 14px 32px;
  box-sizing: border-box;
  background: white;
  border-radius: 16px 16px 0px 0px;
  .step-tilte {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 17px;
    .center-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
    }
    .route-distance {
      font-size: 14px;
      color: rgba(151, 152, 171, 1);
    }
  }
  .step-des {
    width: 100%;
    height: 34px;
    border-radius: 4px;
    background: rgba(254, 242, 234, 1);
    margin: 16px 0 10px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(254, 128, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info-item {
    width: 100%;
    height: 54px;
    padding: 5px 0 5px 5px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(234, 238, 238, 1);
    display: flex;
    align-items: center;
    .k {
      width: 66px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(151, 152, 171, 1);
    }
    .v {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      .route-name {
        flex: 1;
        height: 100%;
      }
      &.navigation-mode {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .bottom-box {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .pre {
      width: 106px;
      height: 46px;
      border-radius: 23px;
      border: 2px solid rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      color: rgba(70, 95, 253, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .next {
      width: 220px;
      height: 46px;
      border-radius: 23px;
      background: rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      &.w-100 {
        width: 100%;
      }
      &.primary-btn-disabled {
        opacity: 0.2;
      }
    }
  }
}
</style>
