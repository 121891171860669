<template>
  <div class="bottom">
    <Tool v-if="isShowTool"></Tool>
    <div class="slot-content">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
import Tool from './Tool.vue';
export default {
  components: {
    Tool,
  },
  props: {
    isShowTool: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped lang="scss">
.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  .slot-content {
    pointer-events: all;
    border-radius: 16px 16px 0 0;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.05);
  }
}
</style>
