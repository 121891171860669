<template>
  <div class="step-two">
    <div class="step-tilte">
      <div class="withdraw" :class="{ disabled: withdrawDisabled }" @click="withdrawClick">
        <img src="@/assets/imgs/withdraw.png" alt="" />
        <span>撤销</span>
      </div>
      <span class="center-title"> ② 调整线路</span>
      <div>
        <van-radio
          class="accuracy"
          :value="accuracyType"
          name="2"
          checked-color="#465FFD"
          @click="changeAccuracyType()"
          >提升精度</van-radio
        >
      </div>
    </div>
    <div class="step-des">拖拽圆点调整线路，单击白色圆点删除线段</div>
    <div class="info-item">
      <div class="k">优先</div>
      <div class="v navigation-mode">
        <van-radio-group :value="navigationMode" direction="horizontal">
          <van-radio name="2" checked-color="#465FFD" @click="changeNavigationMode('2')"
            >机动车道</van-radio
          >
          <van-radio name="1" checked-color="#465FFD" @click="changeNavigationMode('1')"
            >非机动车道</van-radio
          >
        </van-radio-group>
      </div>
    </div>
    <div v-show="isShowPolicy" class="info-item">
      <div class="k">方案</div>
      <div class="v navigation-policy">
        <van-radio-group :value="navigationModeRouteType" direction="horizontal">
          <van-radio
            v-for="(policy, idx) in policyArr"
            :name="policy.value"
            :key="'policy_' + idx"
            checked-color="#465FFD"
            @click="changePolicyClick(policy.value)"
            >{{ policy.name }}</van-radio
          >
        </van-radio-group>
      </div>
    </div>
    <div class="bottom-box">
      <div class="pre" @click="preClick">上一步</div>
      <div class="next" @click="nextClick">下一步</div>
    </div>
  </div>
</template>
<script>
import NDialog from '@/components/NDialog'; // 支持promise的dialog

export default {
  components: {},
  props: ['routeDetailInfo', 'withdrawDisabled', 'isAdjustRoute', 'drivingResult'],
  data() {
    return {
      navigationMode: '2',
      navigationModeRouteType: '0',
      tempPolicy: null, //   修改的优先,临时存储
      tempModel: null, //   修改方案,临时存储
      accuracyType: this.routeDetailInfo.accuracyType
        ? String(this.routeDetailInfo.accuracyType)
        : '1',
    };
  },
  computed: {
    policyArr() {
      let arr = [];
      for (let i = 0; i < this.drivingResult.length; i++) {
        arr.push({ name: `线路${i + 1}`, value: String(i) });
      }
      return arr;
    },
    isShowPolicy() {
      return this.policyArr.length > 0 && this.navigationMode === '2';
    },
  },
  watch: {
    'routeDetailInfo.navigationModeRouteType': {
      handler() {
        this.navigationModeRouteType = this.routeDetailInfo.navigationModeRouteType || '0';
      },
      immediate: true,
    },
    'routeDetailInfo.navigationMode': {
      handler() {
        this.navigationMode = this.routeDetailInfo.navigationMode
          ? String(this.routeDetailInfo.navigationMode)
          : '2';
      },
      immediate: true,
    },
  },
  methods: {
    preClick() {
      this.$emit('onChangeStep', { step: 1 });
    },
    nextClick() {
      this.$emit('onChangeStep', { step: 3 });
    },
    withdrawClick() {
      if (this.withdrawDisabled) return;
      this.$emit('onRevoke');
    },
    changeNavigationMode(val) {
      if (this.navigationMode === val) return;
      if (this.isAdjustRoute) {
        this.changModeNDialog();
        this.tempModel = val;
      } else {
        this.modelChange(val);
      }
    },
    modelChange(val) {
      if (this.navigationMode === val) return;
      this.navigationMode = val;
      this.navigationModeRouteType = '0';
      this.$emit('onChangeNavigationMode', {
        navigationModeRouteType: val,
        navigationMode: val,
        navigationModeRouteType: '0',
      });
    },
    changePolicyClick(val) {
      if (this.isAdjustRoute) {
        this.changModeNDialog();
        this.tempPolicy = val;
      } else {
        this.changePolicy(val);
      }
    },
    changePolicy(val) {
      if (this.navigationModeRouteType === val) return;
      this.navigationModeRouteType = val;
      this.$emit('onChangeDringNavigationModeRouteType', {
        navigationModeRouteType: val,
      });
    },
    // 如果路线调整,修改方案的话给予提醒
    async changModeNDialog() {
      const result = await NDialog.confirm({
        title: '确定更改线路方案吗？',
        message: '更改后，已编辑的线路数据将被清空，请谨慎操作',
      });
      if (result === 'confirm') {
        if (this.tempModel) {
          this.modelChange(this.tempModel);
          this.tempModel = null;
        } else if (this.tempPolicy) {
          this.changePolicy(this.tempPolicy);
          this.tempPolicy = null;
        }
      }
    },
    changeAccuracyType() {
      this.accuracyType = this.accuracyType === '2' ? '1' : '2';
      this.$emit('onChangeAccuracyType', this.accuracyType);
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.step-two {
  width: 100%;
  padding: 16px 14px 32px;
  box-sizing: border-box;
  background: white;
  border-radius: 16px 16px 0px 0px;
  .step-tilte {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .withdraw {
      display: flex;
      align-items: center;
      &.disabled {
        opacity: 0.3;
      }
      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0px;
        // line-height: 39.2px;
        color: rgba(0, 0, 0, 1);
      }
    }
    .center-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
    }
    .accuracy {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .step-des {
    width: 100%;
    height: 34px;
    border-radius: 4px;
    background: rgba(254, 242, 234, 1);
    margin: 16px 0 10px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(254, 128, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info-item {
    width: 100%;
    height: 54px;
    padding: 5px 0 5px 5px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(234, 238, 238, 1);
    display: flex;
    align-items: center;
    .k {
      width: 66px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(151, 152, 171, 1);
    }
    .v {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      .route-name {
        flex: 1;
        height: 100%;
      }
      &.navigation-mode {
        font-size: 14px;
        font-weight: 600;
        .radiogroup {
          justify-content: space-between;
        }
      }
      &.navigation-policy {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .bottom-box {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .pre {
      width: 106px;
      height: 46px;
      border-radius: 23px;
      border: 2px solid rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      color: rgba(70, 95, 253, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .next {
      width: 220px;
      height: 46px;
      border-radius: 23px;
      background: rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
