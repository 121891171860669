<!-- 控制名字/高精显示与否 -->
<template>
  <div class="tool-box" :style="defineStyle">
    <div
      class="tool-item"
      :class="{
        'tool-item-active': $store.state.LogisticsMap.lockGestureMap,
      }"
      @click="lockClick"
    >
      <img
        v-if="$store.state.LogisticsMap.lockGestureMap"
        src="@/assets/LogisticsMap/lock.png"
        alt=""
      />
      <img v-else src="@/assets/LogisticsMap/unlock.png" alt="" />
    </div>
    <div
      class="tool-item"
      :class="{
        'tool-item-active': $store.state.LogisticsMap.isShowLabelLogisticsMap,
      }"
      @click="nameClick"
    >
      <span>名称</span>
    </div>
    <div
      class="tool-item"
      :class="{
        'tool-item-active': $store.state.LogisticsMap.isShowHighPrecisionLogisticsMap,
      }"
      @click="highPrecisionClick"
    >
      <span>高精</span>
    </div>
    <div class="tool-item">
      <img
        @click="getLocationClick"
        class="getLocation"
        src="@/assets/LogisticsMap/common/location.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { isIOS } from '@/utils/index';
import bus from '@/utils/bus';
export default {
  name: 'LogisticsMapTool',
  props: ['defineStyle'],
  data() {
    return {
      isIOS: isIOS(),
    };
  },
  computed: {},
  mounted() {},
  methods: {
    lockClick() {
      this.$store.commit('updateLockGestureMap', !this.$store.state.LogisticsMap.lockGestureMap);
    },
    nameClick() {
      this.$store.commit(
        'updateisShowLabelLogisticsMap',
        !this.$store.state.LogisticsMap.isShowLabelLogisticsMap
      );
      bus.emit('onNameClick');
    },
    highPrecisionClick() {
      this.$store.commit(
        'updateisShowHighPrecisionLogisticsMap',
        !this.$store.state.LogisticsMap.isShowHighPrecisionLogisticsMap
      );
    },
    getLocationClick() {
      bus.emit('onGetLocation');
    },
  },
};
</script>

<style scoped lang="scss">
.tool-box {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 14px;
  box-sizing: border-box;
  .tool-item {
    pointer-events: all;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 13px;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
      z-index: 1;
    }
    img {
      width: 22px;
      height: 22px;
      z-index: 1;
    }
    & + .tool-item {
      margin-top: 12px;
    }
    &.tool-item-active {
      position: relative;
      span {
        color: rgba(255, 255, 255, 1);
      }
      &::before {
        content: '';
        background: rgba(70, 95, 253, 1);
        width: 28px;
        height: 28px;
        border-radius: 6px;
        position: absolute;
      }
    }
    .getLocation {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
